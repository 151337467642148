.content {
  padding: 1rem;
  background-color: rgba(42, 42, 42);
  display: grid;
  grid-template-rows: auto auto;
  justify-content: center;
  align-items: center;
  justify-items: center;
  text-align: center;
}
